import React from 'react';

import { TID } from '@src/types/common';

import { AccountsPayableMDBIcon, BankingAccountsMBDIcon, ReceiptBoxMBDIcon } from '@src/components/utils/icomoon';

import styles from '../style.module.scss';

export const undepositedRevenueTooltipData = [
  {
    id:        '10001',
    iconColor: '#B066FF',
    heading:   'Credit card',
    amount:    800,
    subItems:  [
      { id: '10001-1', heading: 'Amex', amount: 520 },
      { id: '10001-2', heading: 'Shift4', amount: 280 },
    ],
    actionLabel: 'Review',
    actionLink:  () => '#',
  },
  {
    id:          '10002',
    iconColor:   '#FFC266',
    heading:     'Cash and Check',
    amount:      210,
    actionLabel: 'Review',
    actionLink:  () => '#',
  },
];

export const unreadTransactionMessagesTooltipData = {
  id:          '9001',
  heading:     'Unread Transaction Messages',
  actionLabel: 'Review',
  actionLink:  (businessId?:TID) => `/businesses/${businessId}/open_items/unread_txn_messages`,
  key:         'unreadMessageCount',
};

export const expenseReportTooltipData = [
  {
    id:          '1001',
    bgColor:     '#9143FF',
    heading:     'Unverified Invoices',
    actionLabel: 'Review',
    actionLink:  (businessId?:TID) => `/businesses/${businessId}/accounts_payable/queue?type=queued`,
    key:         'uploadedInvoices',
  },
  {
    id:          '1002',
    bgColor:     '#43BAFE',
    heading:     'Unapproved Invoices',
    actionLabel: 'Review',
    actionLink:  (businessId?:TID) => `/businesses/${businessId}/accounts_payable/queue?type=queued&status=approval`,
    key:         'approvedInvoices',
  },
  {
    id:          '1003',
    bgColor:     '#01B787',
    heading:     'Ready to Pay Invoices',
    actionLabel: 'Review',
    actionLink:
    (businessId?:TID) => `/businesses/${businessId}/accounts_payable/queue?type=queued&status=ready_for_payment`,
    key: 'readyToPayInvoices',
  },
];

export const corporateCardTooltipData = {
  id:          '2001',
  heading:     'Unapproved Receipts',
  actionLabel: 'Review',
  actionLink:  (businessId?:TID) => `/businesses/${businessId}/receipt_box/queue?type=unverified`,
  key:         'unapprovedReceipts',
};

export const employeeReimbursementsTooltipData = [
  {
    id:          '3001',
    bgColor:     '#9143FF',
    heading:     'Submitted',
    actionLabel: 'Review',
    actionLink:  () => '#',
    key:         'submitted',
  },
  {
    id:          '3002',
    bgColor:     '#43BAFE',
    heading:     'Approved',
    actionLabel: 'Review',
    actionLink:  () => '#',
    key:         'approved',
  },
  {
    id:          '3003',
    bgColor:     '#01B787',
    heading:     'Reimbursed',
    actionLabel: 'Review',
    actionLink:  () => '#',
    key:         'reimbursed',
  },
];

export const myReimbursementsTooltipData = [
  {
    id:          '4001',
    bgColor:     '#FFAC07',
    heading:     'Uploaded',
    actionLabel: 'Review',
    actionLink:  () => '#',
    key:         'uploaded',
  },
  {
    id:          '4002',
    bgColor:     '#9143FF',
    heading:     'Submitted',
    actionLabel: 'Review',
    actionLink:  () => '#',
    key:         'submitted',
  },
  {
    id:          '4003',
    bgColor:     '#01B787',
    heading:     'Reimbursed',
    actionLabel: 'Review',
    actionLink:  () => '#',
    key:         'reimbursed',
  },
];

export const flaggedItemsTooltipData = [
  {
    id:          '5001',
    bgColor:     '#20b2aa',
    heading:     'Flagged Invoices',
    actionLabel: 'Review',
    actionLink:  (businessId?:TID) => `/businesses/${businessId}/accounts_payable/queue?star_flag=flagged`,
    key:         'flaggedInvoices',
    titleIcon:   <AccountsPayableMDBIcon className={ styles['account-payable-icon'] } fontSize={ 25 } />,
  },
  {
    id:          '5002',
    bgColor:     '#20b2aa',
    heading:     'Flagged Receipts',
    actionLabel: 'Review',
    actionLink:  (businessId?:TID) => `/businesses/${businessId}/receipt_box/queue?star_flag=flagged`,
    key:         'flaggedReceipts',
    titleIcon:   <ReceiptBoxMBDIcon className={ styles['receipt-box-icon'] } fontSize={ 25 } />,
  },
  {
    id:          '5003',
    bgColor:     '#20b2aa',
    heading:     'Flagged Transactions',
    actionLabel: 'Review',
    actionLink:  (businessId?:TID) => `/businesses/${businessId}/open_items/flagged_transactions`,
    key:         'flaggedTransactions',
    titleIcon:   <BankingAccountsMBDIcon className={ styles['bank-transaction-icon'] } fontSize={ 25 } />,
  },
];

export const missingRevenueReportsTooltipData = {
  id:          '7001',
  heading:     "Revenue hasn't arrived for :value days",
  actionLabel: 'Review',
  actionLink:  () => '#',
  key:         'missingRevenueReports',
};

export const unbalancedReportsTooltipData = {
  id:          '7002',
  heading:     'Unbalanced Reports',
  actionLabel: 'Review',
  actionLink:  () => '#',
  key:         'unbalancedReports',
};

export const uncategorizedTransactionsTooltipData = {
  id:          '7003',
  heading:     'Uncategorized Transactions',
  actionLabel: 'Review',
  actionLink:  (businessId?:TID) => `/businesses/${businessId}/reconciliation_center/transactions`,
  key:         'uncategorizedTransactions',
};

export const unclearedDocumentsTooltipData = {
  id:          '7004',
  heading:     'Uncleared Documents',
  actionLabel: 'Review',
  actionLink:  (businessId?:TID) => `/businesses/${businessId}/reconciliation_center/documents`,
  key:         'unclearedDocuments',
};

export const missingBankStatementTooltipData = {
  id:          '7005',
  heading:     'Missing Bank Statement',
  actionLabel: 'Review',
  actionLink:
  (businessId?:TID) => `/businesses/${businessId}/reconciliation_center/month_end_documents/bank_statements`,
  key: 'missingBankStatements',
};

export const closingStatusTooltipData = {
  id:               '7006',
  heading:          'Closed',
  actionLabel:      'view',
  actionLink:       () => '#',
  shortDescription: "Last 100% closing was done for Jan'24",
  key:              'percent',
};

export const auditScanTooltipData = {
  id:          '7007',
  heading:     'Audit Scan',
  actionLabel: 'Review',
  actionLink:  () => '/ai_chatbot',
  key:         'auditScan',
};

export const documentRequestsTooltipData = [
  {
    id:          '8001',
    bgColor:     '#00B887',
    heading:     'Reviewed',
    actionLabel: 'Review',
    actionLink:  (businessId?:TID) => `/businesses/${businessId}/open_items/document_requests/reviewed`,
    key:         'reviewed',
  },
  {
    id:          '8002',
    bgColor:     '#FFB648',
    heading:     'Open',
    actionLabel: 'Review',
    actionLink:  (businessId?:TID) => `/businesses/${businessId}/open_items/document_requests`,
    key:         'open',
  },
];
