import axios, { AxiosRequestConfig } from 'axios';
import castArray from 'lodash/castArray';

import { stringifySearch } from '@src/utils/url_helpers';

import { postRefreshToken } from './tokens';

const authorizationHeaders = () => {
  const token = window.localStorage.getItem('oauth_access_token');

  return {
    Authorization: `Bearer ${token}`,
  };
};

const defaultAxiosConfig = (): AxiosRequestConfig => {
  return {
    baseURL: window.configData.api_server_host_url,
    headers: {
      'Accept':       'application/json',
      'Content-Type': 'application/json',
    },
    paramsSerializer: stringifySearch,
  };
};

const authorizedAxiosConfig = (): AxiosRequestConfig => {
  return {
    baseURL: window.configData.api_server_host_url,
    headers: {
      'Accept':       'application/json',
      'Content-Type': 'application/json',
      ...authorizationHeaders(),
    },
    paramsSerializer: stringifySearch,
  };
};

// This method execute a request created within a function provided as `fn`
// argument. If the request fails with the error code 401 and the refresh token
// in the storage is present, the method tries to refresh the access token and
// the token is refresh retry the request.
// During the token refreshing the lock is acquired (use navigation.locks that
// make locks between different tabs) to prevent multiple parallel refreshing.
const refreshTokenAndRetry = <Type>(
  fn: () => Promise<Type>,
  retryCount: number = 1,
  err: Error | undefined = undefined,
): Promise<Type> => {
  return fn().catch(async (error: any) => {
    // No more retry counts. Failing existing error
    if (retryCount <= 0) throw err || error;
    // No authorization error. Not reasons to refresh access token
    if (error.status !== 401 && error.response?.status !== 401) throw err || error;
    const oldRefreshToken: string = localStorage.getItem('oauth_refresh_token') || '';
    // No refresh token present. Can't refresh access token.
    if (!oldRefreshToken) throw err || error;

    try {
      const locks = (navigator as any).locks;
      await locks.request(
        window.Docyt.Common.Constants.REFRESH_OAUTH_REFRESH_TOKEN,
        async () => {
          const refreshToken = localStorage.getItem('oauth_refresh_token') || '';
          if (oldRefreshToken !== refreshToken) {
            // Assume that token was refresh by another thread
            return;
          }

          const tokens = await postRefreshToken(refreshToken);
          localStorage.setItem('oauth_access_token', tokens.accessToken);
          localStorage.setItem('oauth_refresh_token', tokens.refreshToken);
        },
      );
    } catch {
      // Some error during the token refreshing. Trying again.
      return refreshTokenAndRetry(fn, retryCount - 1, error);
    }
    // The lock is released and token was refreshed by the current or another thread.
    // Retry the request.
    return refreshTokenAndRetry(fn, retryCount - 1, error);
  });
};

const processError = (error: any) => {
  if (error?.response?.status === 404) {
    error.message = window.Docyt.Common.Constants.Messages.NOT_FOUND_STATUS_ERROR_MSG;
  } else if (
    (error?.response?.status === 422 || error?.response?.status === 403)
    && error.response.data?.errors
  ) {
    error.message = castArray(error.response.data.errors).join(', ');
  } else if (
    error?.response?.status === 401
    && error.response.data?.errors
    && error.response.data.errors.some((errorMsg: string) => errorMsg.includes('This refresh token has been expired'))
  ) {
    error.message = window.Docyt.Common.Constants.Messages.UNAUTHORIZED_ERROR_MSG;
  }

  throw error;
};

const notAuthorizedApiRequest = (
  config: AxiosRequestConfig = {},
): Promise<any> => {
  return axios({
    ...defaultAxiosConfig(),
    ...config,
  }).then((response) => response.data).catch(processError);
};

const authorizedApiRequest = (
  config: AxiosRequestConfig = {},
): Promise<any> => {
  return refreshTokenAndRetry(() => {
    return axios({
      ...authorizedAxiosConfig(),
      ...config,
    }).then((response) => response.data).catch(processError);
  });
};

const apiGet = (url: string, params?: object): Promise<any> => {
  return authorizedApiRequest({ method: 'GET', url, params });
};

const apiPost = (
  url: string,
  data: object,
  onUploadProgress?: (progressEvent: any) => void,
): Promise<any> => {
  return authorizedApiRequest({ method: 'POST', url, data, onUploadProgress });
};

const apiPut = (url: string, data: object): Promise<any> => {
  return authorizedApiRequest({ method: 'PUT', url, data });
};

const apiPatch = (url: string, data: object): Promise<any> => {
  return authorizedApiRequest({ method: 'PATCH', url, data });
};

const apiDelete = (url: string, data?: object): Promise<any> => {
  return authorizedApiRequest({ method: 'DELETE', url, data });
};

export {
  authorizedApiRequest,
  notAuthorizedApiRequest,
  apiGet,
  apiPatch,
  apiPost,
  apiPut,
  apiDelete,
  refreshTokenAndRetry,
};
